<template>
  <el-card class="margin-card">
    <div class="bc">
      <div class="bc-l1"></div>
      <div class="bc-t1">保证金</div>
      <div class="bc-l2"></div>
      <div class="bc-t2">缴纳的保证金会冻结在平台，退出平台时会退还保证金余额。</div>
      <router-link to="/user/mymargin/record">
        <div class="bc-t3">历史记录<i class="el-icon-tickets" style="margin-left: 5px"></i></div>
      </router-link>
    </div>
    <div class="bc2">
      <div class="bc2-title">
        <div class="title1">{{ title_1 }}</div>
        <div class="title2">{{ title_2 }}</div>
      </div>
      <div class="img"><img :src="imgurl"></div>
    </div>
    <div class="paymargin">
      <el-card class="pay-card sctp-mar-r20">
        <div class="mymargin">
          <div class="mymargin-t1">当前保证金余额</div>
          <div class="mymargin-t2">最低保证金额度200元</div>
        </div>
        <div class="mar-a">
          <div class="mar-m">{{ mymagin }}</div>
          <div class="mar-u">元</div>
          <div class="mar-b">
            <el-button type="success" size="small"
                       @click="viewPhoneDialogConfig.onextractViewClick()"
            >提取
            </el-button>
          </div>
        </div>
      </el-card>
      <el-card class="pay-card">
        <div class="mymargin">
          <div class="mymargin-t1">可缴纳保证金</div>
        </div>
        <div class="mar-a">
          <div class="mar-m mars">
            <el-select v-model="paymoney" size="small">
              <el-option label="200" value="200"></el-option>
              <el-option label="500" value="500"></el-option>
              <el-option label="1000" value="1000"></el-option>
              <el-option label="2000" value="2000"></el-option>
              <el-option label="3000" value="3000"></el-option>
              <el-option label="5000" value="5000"></el-option>
              <el-option label="8000" value="8000"></el-option>
              <el-option label="10000" value="10000"></el-option>
            </el-select>
          </div>
          <div class="mar-u">元</div>
          <div class="mar-b">
            <el-button type="warning" size="small"
                       @click="viewPhoneDialogConfig.onViewClick()"
            >缴纳
            </el-button>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      center
      title="提取保证金提醒"
      width="400px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="extracttitle">您可提取：</div>
        <div class="extracu">¥</div>
        <div class="extracinput">
          <el-input v-model="extractmoney"></el-input>
        </div>
        <div class="extraccaption">备注：申请后工作人员将会在10个工作日之后审核，如您没有
          任何纠纷，保证金将打到您的账户
        </div>
        <div class="extracbtn">
          <el-button type="warning" size="small" @click="extracMoney()">提取</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="缴纳保证金"
      :visible.sync="viewPhoneDialogConfig.showPayDialog">
      <div class="sctp-tc sctp-mar-b20">您已选择缴纳{{ paymoney }}元</div>
      <pay :pay-amount="paymoney"
           :can-use-wallet="false"
           @pay="viewPhoneDialogConfig.onPay"></pay>
    </el-dialog>
  </el-card>
</template>

<script>

export default {
  name: "mymargin",
  components: {
    pay: () => import('@CMP/common/BuyPay'),
  },
  data() {
    return {
      title_1: "您还未缴纳保证金",
      title_2: "您当前还未缴纳保证金，对方怎么放心跟你交易呢？",
      paymoney: 200,
      mymagin: 0.00,
      extractmoney: 0.00,
      imgurl: '/images/mymargin.png',
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: () => {
          this.viewPhoneDialogConfig.confirmPay();
        }, onextractViewClick: () => {
          this.viewPhoneDialogConfig.show = true;
        }, confirmPay: () => {
          this.viewPhoneDialogConfig.showPayDialog = true;
        },
        onPay: ({payWay}) => {
          this.viewPhoneDialogConfig.showPayDialog = false;
          this.$request.post({
            reqcode: '1172',
            reqdesc: '缴纳或者提取保证金',
            userId: this.user.userId,
            type: 1,
            paytype: payWay,
            money: this.paymoney
          }).then(res => {
            this.querymymagin();
            this.$message.success("缴纳成功");
          })
        }
      },
    }
  }, methods: {
    querymymagin() {
      this.$request.post({
        reqcode: '1171',
        reqdesc: '我的保证金',
        userId: this.user.userId
      }).then(res => {
        this.extractmoney = res.retdata;
        this.mymagin = res.retdata;
        if (parseFloat(res.retdata) > 0) {
          this.title_1 = "您已缴纳保证金";
          this.title_2 = "您还可以继续缴纳保证金,获取更多的信任";
          this.imgurl = '/images/marginsuccess.png';
        }
      })
    }, extracMoney() {
      this.viewPhoneDialogConfig.show = false;
      if (this.extractmoney == 0) {
        this.$message.warning('提取金额不能为0');
        return;
      }
      if (this.mymagin < this.extractmoney) {
        this.$message.warning('提取金额不能大于保证金余额');
        this.extractmoney = this.mymagin
        return;
      }
      this.$request.post({
        reqcode: '1172',
        reqdesc: '缴纳或者提取保证金',
        userId: this.user.userId,
        type: 2,
        money: this.extractmoney
      }).then(res => {
        this.querymymagin();
        this.$message.success("提取成功");
      })
    }
  }, beforeMount() {
    this.querymymagin()
  }
}
</script>

<style scoped lang="scss">
.margin-card {
  ::v-deep {
    height: 610px;

    .bc {
      position: relative;
      display: flex;
      font-size: 15px;
      line-height: 20px;

      .bc-l1 {
        width: 3px;
        height: 20px;
        background-color: darkorange;
        margin-right: 10px;
      }

      .bc-l2 {
        width: 2px;
        height: 15px;
        background-color: #b0b0b0;
        margin: 4px 20px 0 20px;
      }

      .bc-t3 {
        font-size: 13px;
        color: #939090;
        line-height: 24px;
        position: absolute;
        right: 50px;
      }
    }
  }
}

.margin-card .bc-t2 {
  font-size: 13px;
  color: #939090;
  line-height: 24px
}

.margin-card .bc2 {
  height: 114px;
  background-color: #f2f2f2;
  margin-top: 20px;
  position: relative;

  .title1 {
    font-size: 16px;
    color: #333333;
  }

  .title2 {
    font-size: 12px;
    color: #939090;
    margin-top: 20px;
  }

  img {
    width: 75px;
    height: 75px;
    position: absolute;
    right: 40px;
    top: 20px;
  }
}

.margin-card .bc2-title {
  padding: 30px 45px;
}

.margin-card .paymargin {
  margin-top: 30px;
  display: flex;
}

.margin-card .paymargin .pay-card {
  width: 49%;
}

.margin-card .mymargin {
  height: 30px;
  border-bottom: 1px solid #e3dfdf;
}

.margin-card .mymargin-t1 {
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  color: #515151;
  font-size: 14px;
  float: left;
}

.margin-card .mymargin-t2 {
  font-size: 14px;
  color: #7B7B7B;
  float: right;
}

.margin-card ::v-deep .el-card__body {
  padding: 12px;
}

.margin-card .mar-a {
  height: 100px;
  padding: 20px;
  padding-top: 34px;
}

.margin-card .mar-m {
  float: left;
  font-size: 27px;
  color: #239D39;
}

.margin-card .mar-u {
  float: left;
  color: #7B7B7B;
  font-size: 14px;
  line-height: 45px;
  padding-left: 10px;;
}

.margin-card .mar-b {
  float: right;
}

.margin-card .mars {
  width: 110px;
  font-size: 22px;
}

.margin-card ::v-deep .el-button--warning {
  color: #FFF;
  background-color: #ff6600;
  border-color: #ff6600;
}

.margin-card .extracinput {
  margin: 10px 0px;
  margin-left: 75px;
  width: 300px;

}

.margin-card .extracttitle {
  font-size: 13px;
  color: #939090;
}

.margin-card .extraccaption {
  font-size: 12px;
  color: #939090;
}

.margin-card .ex {
  font-size: 13px;
  color: #939090;
}

.margin-card .extracu {
  position: absolute;
  top: 120px;
  left: 157px;
  z-index: 10;
  font-size: 15px;
  color: #939090;
}

.margin-card .extracinput ::v-deep .el-input__inner {
  border: 0px;
  border-bottom: 1px solid #e3dfdf;
  font-size: 24px;
  color: #939090;
  width: 80%;
  padding-left: 80px;
}

.margin-card .extracbtn {
  margin-top: 23px;
  margin-left: 149px;
}

.margin-card ::v-deep .el-dialog {
  margin-top: 30vh !important;
}
</style>
